import 'bootstrap/dist/css/bootstrap.min.css';

export default function RehearsalLocationMapInfo () {

    return (
        <>
            <div className= 'rehearsalInfoContainer flex object-contain w-auto h-5/12 pt-11 mx-10 gap-8 align-baseline justify-between'>
                <div className='text-left'>
                    <p className='mb-2 underline'> Rehearsal Info:</p>
                    <p className='mb-0.5'> Hancock United Church of Christ</p>
                    <p className='mb-0.5'> 1912 Massachusetts Ave.</p> 
                    <p className='mb-3'>Lexington, MA 02421</p>
                    <p className='0.5'>1st, 3rd, and 5th Wednesdays of the month</p>
                </div>
                <div className = 'googleFrameContain'>
                    <iframe title ="RehearsalMap" className = 'googleFrame' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2943.997299127487!2d-71.23417472388603!3d42.44907357118497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e39dd1c96595af%3A0x5c76be86c3ff8247!2sHancock%20United%20Church%20Of%20Christ!5e0!3m2!1sen!2sus!4v1703263888628!5m2!1sen!2sus" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>
    )
}