import fb from "../misc/icon-facebook.svg"
import insta from "../misc/icon-instagram.svg"
import twitter from "../misc/icon-twitter.svg"

export default function Footer(){
    return (
        <footer className="bg-slate-950 relative bottom-0 object-cover object-right w-full h-36 bg-cover">
                        <div className="flex flex-col items-center justify-center h-full">
                        <p className="text-white opacity-50 sm:text-center text-sm pb-2">Visit us on:</p>
                        <div className="flex gap-6">
                                <a href = 'https://www.facebook.com/VocaMotionGB'><img src={fb} alt="Facebook Icon"/></a>
                                <a href = 'https://www.instagram.com/vocamotion_greaterboston/'><img src={insta} alt="Instagram Icon"/></a>
                                <a href = 'https://twitter.com/VocaMotionGBC'><img src={twitter} alt="Twitter Icon"/></a>
                            </div>
                            <br></br>
                            <div className='text-center px-10'>
                                <p className="text-white opacity-50 mt-auto sm:text-right text-xs sm:text-sm">© Copyright 2023. All Rights Reserved. <a className ='credits innerLink' href="/credits"> Website Credits </a></p>
                            </div>
            </div>
        </footer>
    )
}