import LeadershipTeamBubbles from "../components/LeadershipTeamBubbles";

export default function About () {

    return (
        <>
            <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">About Us</h1>
            <div className='w-11/12 m-auto'>
                <div className='text-justify bg-slate-800 rounded-2xl p-5'>
                    <p>
                        In January 2023, VocaMotion was formed to create a space for SSAA singers of all gender identities to come together in a cappella harmony. 
                        We are an ambitious chorus who value individual growth as singers and artists, as well as the joy that is sparked by creating beautiful music together.
                    </p>
                    <p>
                        Though we sing a cappella music of all sorts, we specialize in the barbershop style. As such, we are affiliated with and compete in the Barbershop Harmony Society,
                        and are proud to be the first treble-voiced chorus in the Northeastern District (Greater Boston Chapter, Northeastern District). We participated in our first competition in April 2023 where we placed 2nd overall in our division.
                        We continued this good growth in October 2023, where we scored 3rd overall in our district. 
                    </p>
                    <p>
                        To learn more about us, show support, or just reach out and say hey, follow us on Instagram (<span className='font-bold'>@vocamotion_greaterboston</span>) or our Facebook page <span className='font-bold'>VocaMotion</span>.
                    </p>
                    <p>
                        Interested in joining or visiting? See our <a className='innerLink' href='/auditions'> audition info </a> page for more details!
                    </p>
                </div>
                <LeadershipTeamBubbles/>
                <p className='italic nondiscriminationPolicy'> The Greater Boston Chapter of the Barbershop Harmony Society, which includes all its performing groups, does not unlawfully discriminate internally (in its administrative and program operations) or externally (in provision of services) on the basis of race, political orientation, religion, gender, sexual orientation, age,
                    national origin, ethnicity, ancestry, marital status, veteran status, or mental or physical disability or any other status prohibited by applicable law.</p>
                <p className="italic nondiscriminationPolicy"> The Chapter is a 501(c)(3) non-profit with EIN 23-7066982.</p>
            </div>
        </>
    )
}