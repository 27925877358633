import ContactForm from "../components/ContactForm";
import RehearsalLocationMapInfo from "../components/RehearsalLocationMapInfo";

export default function Audition () {

    return (
        <>
            <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Audition Info</h1>
            <div className='w-11/12 m-auto'>
                <div className='text-justify pb-12'>
                    <div className ='bg-slate-800 rounded-2xl p-5'>
                        <p>
                            VocaMotion is open to all singers, regardless of gender, that are comfortable singing in the SSAA range. We are an <span className='underline'>auditioned</span> chorus, and we recommend that prospective singers attend at least <span className='underline'>two</span> rehearsals before requesting an audition. Rehearsals allow you to become familiar with the repertoire as well as the singing style of the chorus. 
                            Auditions will happen live, and on a per-auditionee basis. We will give you all the materials you need to learn the audition song beforehand.
                        </p>
                        <p>
                            As a chorus, we hold ourselves to a standard of continuously improving in both vocal quality and performance. Members are expected to practice music outside of rehearsal time, and to show up prepared to sing and perform without holding sheet music or iPads. We do not hold music in performance, so the ability to memorize is important. 
                            That said, being able to read sheet music or past experience in music at all are not required, but they can be assets. These are skills we will work on in rehearsal, so just show up ready to learn!  
                        </p>
                        <p>
                            Rehearsals take place on the <span className="underline">1st, 3rd, and 5th Wednesdays of every month from 7-10 PM at the Hancock United Church of Christ in Lexington, MA.</span> Walk-ins are welcome, but you can always reach out to us over Facebook, Instagram, or email (membership@vocamotion.org) for more details or if want to give us a heads up. We love having guests, so we welcome you to sit in or even sing with us! Hope to see you there!
                        </p>
                        <ContactForm/>
                    </div>
                    <RehearsalLocationMapInfo/>
                </div>
            </div>
        </>
    )
}

/* <div><iframe src="contact-form.html" class="container"></iframe></div> */