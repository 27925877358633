import vomoCard1 from '../misc/vomodistricts.jpg';
import vomoCard2 from '../misc/vomofestivalSing.jpg';
import vomoCard3 from '../misc/vomothirdplace.jpg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/src/modal';


export default function PageInfoCards() {
  return (
    <>
        <article class="postcard dark blue">
                <img class="postcard_img" src={vomoCard1} alt="VocaMotion History" data-bs-toggle='modal' data-bs-target='#imageModal1'/>
                <div className='modal fade block' id='imageModal1' tabIndex='-1' aria-hidden='true'>
                     <div className="modal-dialog bg-none h-[90%]" data-bs-dismiss="modal">
                        <div className="modal-content bg-none m-auto h-full">
                                 <img class="d-block m-auto" src={vomoCard1} alt="VocaMotion History" data-bs-toggle='modal' data-bs-target='#imageModal1'></img>
                        </div>
                    </div> 
                </div>
            <div class="postcard_text">
                <h1 class="postcard_title blue"><a href="/about">Chorus History</a></h1>
                <div class="postcard_bar" style={{height:2}}></div>
                <div class="postcard_preview-txt">Founded in January 2023, VocaMotion has joined its TTBB sibling chorus Vocal Revolution in bringing the joy of barbershop singing to all members of the Greater Boston community. As a new chorus, and as the first SSAA chorus in the Northeastern District of the Barbershop Harmony society, we recognize our unique position, and strive to grow, learn and challenge ourselves to become the best we can be. </div>
            </div>
            </article>
            <article class="postcard dark blue">
            <img class="postcard_img" src={vomoCard2} alt="VocaMotion History" data-bs-toggle='modal' data-bs-target='#imageModal2'/>
                <div className='modal fade m-auto' id='imageModal2' tabIndex='-1' aria-hidden='true'>
                     <div className="modal-dialog bg-none m-auto  h-[90%]" data-bs-dismiss="modal">
                        <div className="modal-content bg-none m-auto h-full">
                                 <img class="d-block m-auto" src={vomoCard2} alt="VocaMotion History" data-bs-toggle='modal' data-bs-target='#imageModal2'></img>
                        </div>
                    </div> 
                </div>
            <div class="postcard_text">
                <h1 class="postcard_title blue"><a href="/">Upcoming Events</a></h1>
                <div class="postcard_bar"></div>
                <div class="postcard_preview-txt">VocaMotion does two chapter shows a year alongside Vocal Revolution: one in the spring and one in the winter. We also do a number of community gigs such as the annual Boston A Cappella Festival, and compete across the country 1-3 times a year. See our events calendar or follow us on social media to see our list of upcoming events!</div>
                <div class="postcard_preview-txt">   </div>
            </div>
            </article>
            <article class="postcard dark blue">
            <img class="postcard_img" src={vomoCard3} alt="VocaMotion Contact" data-bs-toggle='modal' data-bs-target='#imageModal3'/>
                <div className='modal fade m-auto' id='imageModal3' tabIndex='-1' aria-hidden='true'>
                     <div className="modal-dialog bg-none m-auto h-[90%]" data-bs-dismiss="modal">
                        <div className="modal-content bg-none m-auto h-full">
                                 <img class="d-block m-auto" src={vomoCard3} alt="VocaMotion Contact" data-bs-toggle='modal' data-bs-target='#imageModal3'></img>
                        </div>
                    </div> 
                </div>
            <div class="postcard_text">
                <h1 class="postcard_title blue"><a href="/auditions">Interested? Contact Us!</a></h1>
                <div class="postcard_bar"></div>
                <div class="postcard_preview-txt">Rehearsals take place at Hancock United Church of Christ in Lexington, MA from 7 p.m. to 10 p.m. on the 1st, 3rd, and 5th Wednesdays of every month.</div>
                <br></br>
                <div class="postcard_preview-txt">Contact us if you are interested, or check social media for our regular open rehearsal days! For TTBB-voiced singers, we encourage you to check out our men's chorus: <a className ="innerLink" href="https://www.vocalrevolution.org/">Vocal Revolution</a>.</div>
                <br></br>
                <div class="postcard_preview-txt">Call/text: 339-970-8720 </div>
                <div class="postcard_preview-txt">Email: membership@vocamotion.org </div>
                <div class="postcard_preview-txt">
                    <a href="/auditions"><button className ="homePageButton" id="auditionInfoButton">
                        Audition Info 
                    </button>
                    </a>
                </div>
            </div>
        </article>
    </>
  )
}

