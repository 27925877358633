import ImageCarousel from "../components/ImageCarousel";
import PageInfoCards from "../components/PageInfoCards";
import EventCalendarCards from "../components/EventCalendarCards";

import 'bootstrap/dist/css/bootstrap.min.css';

export default function Home (){

    const handleLearnMoreClick = () => {
        window.location.href = "/about";
    };

    return (
        <>
            <ImageCarousel/>
            <div className ="bannerText flex flex-col items-center h-full text-white text-lg text-center font-bold w-11/12">
                <div class="bannerText flex flex-col items-center h-full text-white text-lg text-center font-bold w-10/12">
                    <p>VocaMotion Treble Chorus</p> 
                    <hr></hr>
                    <p>Greater Boston Chapter of the Barbershop Harmony Society</p>
                    <br></br>
                    <a href="/about">
                        <button className ="homePageButton callToAction" onClick={handleLearnMoreClick}> Learn More! </button>
                    </a>
                </div>
            </div>
            <div class="container py-4">
                <h1 class="h1 text-center" id="pageHeaderTitle">Who We Are</h1>
                <p class = "vocamotionSummary"> 
                    VocaMotion is a chorus for treble-voiced (SSAA) singers of all gender identities to come together in barbershop harmony. We are an ambitious ensemble, whose members come from all over the Greater Boston area to experience the joy and fulfillment of creating beautiful a cappella music.
                </p>
                <EventCalendarCards/>
                <PageInfoCards/>
            </div>
        </>
    )
}