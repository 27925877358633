import 'bootstrap/dist/css/bootstrap.min.css';

export default function EventCalendarCards() {
  const events = [
    { date: "August 21", title: "Open Rehearsal", link: "/open-rehearsal",  opacity: 1 },
    { date: "October 14", title: "Boston A Acappella Festival", link: "https://www.vocalrevolution.org/2024-oct-boston-a-cappella", opacity: 0.5 },
    { date: "October 25-27", title: "NED District Contest", link: "", opacity: 0.25 }
  ];

  return (
    <>
      <h1 class="h1 text-center" id="pageHeaderTitle">Upcoming Events</h1>
      <div class='calendarCardContainer'>
        {events.map((event, index) => (
          <a key={index} class={`calendar linked`} href={event.link} style={{ opacity: event.opacity }}>
            <div class="calendar-body">
              <span class="month-name">{event.date}</span>
              <span class="eventTitle">{event.title}</span>
            </div>
          </a>
        ))}
      </div>
    </>
  )
}