import vomoImage from '../misc/westwoodgig.png';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ContactForm() {
  return (
    <>
        <div className= 'rounded-2xl bg-slate-800 mt-10 formContainer flex object-contain w-auto h-5/12 pt-6 pb-2 gap-8 align-baseline justify-between'>
            <form className='flex flex-col w-100'action="https://formspree.io/f/xeqbwykj" method="post">
                <label for="name">Full Name</label>
                <input className='bg-slate-900 border-solid  border-sky-500 rounded-lg mt-1 mb-3 p-[2px] pl-[5px]'name="name" id="name" type="text"/>
                <label for="pronouns">Pronouns</label>
                <input className='bg-slate-900 border-solid  border-sky-500 rounded-lg mt-1 mb-3 p-[2px] pl-[5px]'name="pronouns" id="pronouns" type="text"/>
                <label for="email">Email</label>
                <input className='bg-slate-900 border-solid  border-sky-500 rounded-lg mt-1 mb-3 p-[2px] pl-[5px]'name="email" id="email" type="email"/>
                <label for="voicePart">Voice Part</label>
                <input className='bg-slate-900 border-solid  border-sky-500 rounded-lg mt-1 mb-3 p-[2px] pl-[5px]'name="voicePart" id="voicePart" type="text"/>
                <label for="moreInfo">Why VocaMotion?</label>
                <textarea className='bg-slate-900 border-solid  border-sky-500 rounded-lg pb-5 mt-1 mb-4 p-[2px] pl-[5px]'name="moreInfo" id="moreInfo" type="textarea"/>
                <button className ='homePageButton' type="submit">Submit!</button>
            </form>
            <div className='formImageContainer'>
                <img className='rounded-lg h-full object-cover formImage' src={vomoImage} alt='Vocamotion Chorus'></img>
            </div>
        </div>
    </>
  )
}