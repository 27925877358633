export default function OpenRehearsal () {

    return (
        <>
        <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Open Rehearsal - 8/21/2024</h1>
            <div className='w-11/12 m-auto mt-0'>
                <div className='text-justify pb-10'>
                    <div className='bg-slate-800 rounded-2xl p-5 pt-0 mt-0'>
                        <br></br>
                        <br></br>
                        <p>VocaMotion is having an open rehearsal on August 21, 2024-- kicking off the end of summer and prepping for the NED District contest this October! Join us for a fun-filled night of singing, and learn what VocaMotion and our repertoire are about. 
                            No need to prepare anything-- just walk in as you are.
                            If you have any questions, or just want to tell us you are coming, please respond to our Facebook event, or email membership@vocamotion.org. We hope to see you there!</p>
                        <br></br>
                        <p className='pb-1'><span className='underline'>Venue</span>:&nbsp; 
                             <a className='innerLink' 
                            href='https://www.google.com/maps/place/Hancock+United+Church+Of+Christ/@42.4490736,-71.2341747,17z/data=!3m1!4b1!4m6!3m5!1s0x89e39dd1c96595af:0x5c76be86c3ff8247!8m2!3d42.4490736!4d-71.2315998!16s%2Fg%2F1tf32d5w?entry=ttu'>  
                                Hancock United Church Of Christ, 1912 Massachusetts Ave, Lexington, MA 02421</a>
                        </p>
                        <p className='pb-1'><span className='underline'>Date</span>: August 21, 2024</p>
                        <p className='pb-1'><span className='underline'>Time</span>: Rehearsal goes from 7pm-10pm. There is also an Afterglow (drinks/late night foods) at the Yardhouse restaurant in Burlington, MA from 10-11pm!</p>
                    </div> 
                </div>
            </div>
        </>
    )
}