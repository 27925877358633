import banner from "../../misc/summerFundraiser.jpg";

export default function SummerFundraiser () {
    return (
        <>
        <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Summer Fundraiser!</h1>
            <div className='w-11/12 m-auto mt-0'>
                <div className='text-justify pb-10'>
                    <div className='bg-slate-800 rounded-2xl p-5'>
                        <p>VocaMotion and Vocal Revolution are excited to announce our newest fundraiser, going on now until June 23rd!</p>
                        <br></br>
                        <img className='m-auto h-1/3 w-1/3' src={banner} alt='Event Banner'></img>
                        <br></br>
                        <p>It has always been the goal of our choruses to enrich lives through the joyful pursuit of barbershop harmony, fostering fellowship, musical excellence, and community engagement. Your support is valuable to us, and we are incredibly humbled and grateful to have such an amazing community behind us. That's why, with these funds, we will do our part to actively give back to our supporters via sponsoring youth barbershop programs, hosting community events in the Boston area, keeping our annual shows accessible and FREE, and making sure that we provide the utmost happiness to everyone in our choruses and out.
                        </p>
                        <p>This fundraiser is possible through collaboration with Traveler's Birdies for Charity program, sponsored by Webster Bank. For every donation made, Travelers will match it 15%. All of the money contributed by Travelers will go to a charity of our choice. 
                        </p>
                        <p>To donate, click the button below:</p>
                        <div className ='flex justify-center pt-3'>
                            <a className='w-[39%]' href="https://partnerhq.com/public/events/2024-travelers-championship-birdies-for-charity/organization_partnerships/3760"><button style={{width:'100%'}} className ="homePageButton w-full" id="auditionInfoButton">
                            Donate Now!
                            </button>
                            </a>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    )
}