export default function Credits () {

    return (
        <>
            <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Credits</h1>
            <div className='w-11/12 ml-auto mr-auto pb-10'>
                <div className='text-justify bg-slate-800 rounded-2xl p-5'>
                    <ul className = "list-disc space-y-5 > * + *">
                        <li> Website created with React and Tailwind CSS.</li>
                        <li> Image carousel, home page cards, modal images were created with Bootstrap 5</li>
                        <li> Navigation bar (disclosure panels/transitons) was created with HeadlessUI and help from <a href ="https://www.frontendmentor.io/challenges/audiophile-ecommerce-website-C8cuSd_wx">Frontend Mentor </a></li>
                        <li> Map display created by Google Maps </li>
                    </ul>
                </div>
            </div>
        </>
    )
}