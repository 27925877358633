import NavBar from "../components/NavBar"

export default function CommonLayout () {
    return (
        <>
        <div className="relative h-16 w-full">
            <NavBar/>
        </div>
        </>
    ) 
}