import eventBanner1 from '../../misc/nightandday.jpg';

export default function GenericShowPage () {
    return (
        <>
        <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Night and Day - 6/1</h1>
            <div className='w-11/12 m-auto mt-0'>
                <div className='text-justify pb-10'>
                    <div className='bg-slate-800 rounded-2xl p-5'>
                        <p>VocaMotion and Vocal Revolution are excited for our first chapter show of the season: <span className='font-bold'> Night and Day</span>!</p>
                        <img className='m-auto h-1/3 w-1/3' src={eventBanner1} alt='Event Banner'></img>
                            <br></br>
                            <br></br>
                            <p>The first of our two annual shows, Night and Day is a family-friendly event, and seeks to celebrate the everchanging nature of life-- happy beginnings, necessary endings, hope, anticipation, mystery, and many more. And if one show wasn't enough, for the first time we are hosting TWO shows in one day! Admission is FREE to the public, thanks to support we get from our sponsors and your donations-- so come once, or twice if you fancy! If you would like to donate and help us continue to put on shows like this, see details below. </p>
                            <br></br>
                            <p>This event will feature the talents of:
                                <ul className='list-disc'>
                                    <li>Vocal Revolution</li>
                                    <li>VocaMotion</li>
                                </ul>
                            </p>
                            <p>And quartets:
                                <ul className='list-disc'>
                                    <li>Daily Special</li>
                                    <li>Praxis</li>
                                    <li>Summer Street</li>
                                </ul>
                            </p>
                            <br></br>
                            <p className='pb-1'><span className='underline'>Venue</span>:
                                <a className='innerLink' href='https://www.google.com/maps/dir//firsdt+parish+arlington/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x89e3765064795ebd:0x1391183a4af05e1?sa=X&ved=2ahUKEwiBlJWmyMKCAxXrl4kEHX5BBVYQ9Rd6BAhFEAA'> First Parish Unitarian Universalist Church, 630 Mass Ave., Arlington, MA 02476</a>
                            </p>
                            <p className='pb-1'><span className='underline'>Time</span>: Shows start at 1pm and 7pm on June 1st, 2024. Each show will have one short intermission each. Doors open at 12:30pm and 6:30pm, respectively.</p>
                            <p><span className='underline'>Recommended Charitable Donation</span>: $15 or $20 per person. Receipts available. You can also save time by donating in advance to our <a className='innerLink' href='/summer-fundraiser'>Birdies for Charity</a> summer fundraiser. All givers of $100+ are acknowledged as annual sponsors in our program (time allowing), on our Donations page, and on the VR website. </p>
                    </div> 
                </div>
            </div>
        </>
    )
}