import vomoCarousel1 from '../misc/vomodistrictstag.png';
import vomoCarousel2 from '../misc/vomofestivalcrop.jpg';
import vomoCarousel3 from '../misc/vomodivisionsCrop.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';

export default function ImageCarousel() {
  return (
    <div className="carouselContainer">
        <Carousel slide={false}>
            <Carousel.Item>
                <div className="carouselImage opacity-[.43]">
                    <img src={vomoCarousel1} alt="VocaMotion Chorus First Slide"/>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carouselImage opacity-[.43]">
                    <img src={vomoCarousel2} alt="VocaMotion Chorus Second Slide"/>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carouselImage  opacity-[.43]">
                    <img src={vomoCarousel3} alt="VocaMotion Chorus Third Slide"/>
                </div>
            </Carousel.Item>
        </Carousel>
    </div>
  )
}